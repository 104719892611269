<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="date = parseDate(startDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :clearable="true"
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="date = parseDate(endDateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.orderCode"
                    :label="$t('voucherUsed.orderCode')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.serial"
                    :label="$t('evoucher.voucherSerial')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.voucherName"
                    :label="$t('voucherUsed.voucherName')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.usedSite"
                    :label="$t('report.supplier.usedSite')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    :clearable="true"
                    v-model="search.customerEmail"
                    :label="$t('customer.emailCustomer')"
                    @keyup.enter="getVoucherUsages"
                  />
                </v-flex>
                <v-flex
                  :class="'lg5-custom'"
                  xs12
                  md3>
                  <v-select
                    v-model="search.voucherStatus"
                    :items="voucherStatusList"
                    :label="$t('evoucher.voucherStatus')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="getVoucherUsages()"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('report.supplier.voucherUsageTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create check voucher -->
            <v-btn
              v-if="!isEntityDisabled()"
              color="success"
              dark
              style="float: right;"
              @click="onExportExcel()"
            >
              <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
              <span style="text-transform: none;">{{
                $t("voucherReport.exportExcel")
              }}</span>
            </v-btn>
          </template>
          <v-data-table
            :headers="voucherInfoHeaders"
            :items="voucherInfoList"
            :no-data-text="$t('common.noDataAvailable')"
            v-model="selected"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <tr
                :class="props.item.isLoading ? 'bg-1' : ''"
                style="position: relative;"
              >
                <!-- <td>
                  <v-checkbox
                    v-model="props.selected"
                    primary
                    hide-details />
                </td> -->
                <td
                  v-for="(header, index) in voucherInfoHeaders"
                  :key="header.value + index"
                >
                  <span v-if="header.value == 'price'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span v-else-if="header.value == 'usedPrice'">
                    {{ formatMoney(props.item[header.value]) }}
                  </span>
                  <span v-else-if="header.value == 'reconciliationStatus'">
                    {{
                      $t(getReconciliationStatusText(props.item[header.value]))
                    }}
                  </span>
                  <span v-else-if="header.value == 'voucherStatus'">
                    {{ $t(getVoucherStatusName(props.item[header.value])) }}
                  </span>
                  <span
                    v-else-if="header.value == 'customerUsed'">
                    <span v-if="props.item.customer.name">
                      <b>{{ props.item.customer.name }}</b><br>
                    </span>{{ formatCustomerInfo(props.item.customer) }}
                  </span>
                  <span
                    v-else-if="header.value == 'voucherOwner'">
                    <span v-if="props.item.owner.name">
                      <b>{{ props.item.owner.name }}</b><br>
                    </span>{{ formatCustomerInfo(props.item.owner) }}
                  </span>
                  <span v-else-if="header.value == 'activationStaff'">
                    {{ props.item[header.value] }}
                    <span v-if="props.item.activationStaffEmail">({{ props.item.activationStaffEmail }})</span>
                  </span>
                  <span v-else>{{ props.item[header.value] }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import moment from 'moment'
import stringUtils from 'utils/stringUtils'
import EntityType from 'enum/entityType'
import VoucherReconciliationType from 'enum/voucherReconciliationType'
import VoucherStatusType from 'enum/voucherStatusType'
import XLSX from 'xlsx'
export default {
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherUsed.voucherName', value: 'name' },
      // { text: 'evoucher.issuance.workpiece', value: 'workpiece' },
      { text: 'report.supplier.seriCode', value: 'serial' },
      { text: 'voucherUsed.orderCode', value: 'orderCode' },
      { text: 'voucherUsed.price', value: 'price' },
      { text: 'report.supplier.dateOfUse', value: 'usedTime' },
      { text: 'report.supplier.usedSite', value: 'usedSite' },
      { text: 'report.supplier.activationStaff', value: 'activationStaff' },
      { text: 'evoucher.voucherStatus', value: 'voucherStatus' },
      { text: 'evoucher.customerUsed', value: 'customerUsed' },
      { text: 'report.supplier.voucherOwner', value: 'voucherOwner' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    selected: [],
    btnCheckVoucherLoading: false,
    search: {
      serial: null,
      orderCode: null,
      voucherName: null,
      usedSite: null,
      customerEmail: null,
      voucherStatus: VoucherStatusType.USED
    },
    voucherStatusList: [
      {
        text: '---',
        value: null
      },
      {
        text: 'evoucher.status.used',
        value: VoucherStatusType.USED
      },
      {
        text: 'evoucher.status.locked',
        value: VoucherStatusType.LOCKED_USING
      }
    ],
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false,
    minStartDate: null,
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false,
    minEndDate: null,
    voucherReconciliationType: VoucherReconciliationType.NOT_SUBMIT_YET,
    voucherReconciliationTypeList: stringUtils.VoucherReconciliationTypeList,
    supplierId: null,
    supplierList: [],
    exportExcelData: {
      headerList: [
        'common.nonumber',
        'report.supplier.usedSite',
        'voucherUsed.voucherName',
        'voucherUsed.price',
        'report.supplier.dateOfUse',
        'report.supplier.activationStaff',
        'voucherUsed.orderCode',
        'report.supplier.seriCode',
        'report.supplier.customerUsedName',
        'report.supplier.customerUsedPhone',
        'report.supplier.customerUsedEmail',
        'report.supplier.voucherOwner',
        'report.supplier.voucherOwnerPhone',
        'report.supplier.voucherOwnerEmail',
        'evoucher.voucherStatus'
      ],
      dataList: []
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHERS_USED_LIST_DATA'
    ])
  },
  watch: {
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD')
        .add(1, 'days')
        .format('YYYY-MM-DD')
    },
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    },
    VOUCHERS_USED_LIST_DATA () {
      let res = this.VOUCHERS_USED_LIST_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle response data
      let voucherData = res.results
      this.voucherInfoList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherData[i].usage_info.id,
          orderCode: voucherData[i].usage_info.code,
          serial: voucherData[i].voucher_info.serial,
          name: voucherData[i].voucher_info.name,
          price: voucherData[i].voucher_info.price,
          supplier: voucherData[i].voucher_info.supplier,
          usedTime: dateUtils.formatBeautyDate(voucherData[i].usage_info.used_date),
          usedSite: voucherData[i].usage_info.used_site,
          usedPrice:
            voucherData[i].usage_info.price &&
            voucherData[i].usage_info.price > 0
              ? voucherData[i].usage_info.price
              : voucherData[i].voucher_info.price,
          reconciliationStatus: voucherData[i].usage_info.reconciliation_status,
          check: '',
          arrivalDate: dateUtils.formatBeautyDate(voucherData[i].usage_info.start_date),
          departureDate: dateUtils.formatBeautyDate(voucherData[i].usage_info.end_date),
          owner: {
            name: functionUtils.concatFullname(
              voucherData[i].owner_info.last_name,
              voucherData[i].owner_info.first_name
            ),
            phone: voucherData[i].owner_info.phone,
            email: voucherData[i].owner_info.email
          },
          customer: {
            name: voucherData[i].customer_info.customer_name,
            phone: voucherData[i].customer_info.customer_phone,
            email: voucherData[i].customer_info.customer_email
          },
          activationStaff: functionUtils.concatFullname(
            voucherData[i].user_created_info.last_name,
            voucherData[i].user_created_info.first_name
          ),
          activationStaffEmail: voucherData[i].user_created_info.email,
          voucherStatus: voucherData[i].voucher_info.status
        }
        this.voucherInfoList.push(obj)
      }
    }
  },
  created () {
    this.getVoucherUsages()

    if (FOR_CONASI_TARGET) {
      this.voucherInfoHeaders.push({ text: 'evoucher.arrivalDate', value: 'arrivalDate' })
      this.voucherInfoHeaders.push({ text: 'evoucher.departureDate', value: 'departureDate' })

      this.exportExcelData.headerList.push('evoucher.arrivalDate')
      this.exportExcelData.headerList.push('evoucher.departureDate')
      this.exportExcelData.headerList.push('common.note')
    }
  },
  methods: {
    isEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    formatCustomerInfo: function (user) {
      if (!functionUtils.isEmptyString(user.email) || !functionUtils.isEmptyString(user.phone)) {
        var extra = null
        if (functionUtils.isEmptyString(user.email) || functionUtils.isEmptyString(user.phone)) {
          extra = user.email ? user.email : '' + user.phone ? user.phone : ''
        } else {
          extra = user.email + ' - ' + user.phone
        }
        return '(' + extra + ')'
      }
      return ''
    },
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVoucherUsages()
    },
    /**
     * Get vouchers usage
     */
    getVoucherUsages: function () {
      let filter = {
        params: {
          page: this.paginate.currentPage,
          serial: this.search.serial,
          voucherOrderCode: this.search.orderCode,
          reconciliationType: this.voucherReconciliationType,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          voucherName: this.search.voucherName,
          usedSite: this.search.usedSite,
          customerEmail: this.search.customerEmail,
          voucherStatus: this.search.voucherStatus
        }
      }
      this.isLoading = true
      this.GET_VOUCHERS_USED_LIST(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getReconciliationStatusText: function (value) {
      return functionUtils.getReconciliationStatusText(value)
    },
    onExportExcel: function () {
      if (this.isEntityDisabled()) {
        return // Locked or expired entity will not able to perform these actions
      }
      let filter = {
        params: {
          serial: this.search.serial,
          voucherOrderCode: this.search.orderCode,
          reconciliationType: this.voucherReconciliationType,
          startDate: !functionUtils.isEmptyString(this.startDateFormatted)
            ? dateUtils.formatDate(this.startDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          endDate: !functionUtils.isEmptyString(this.endDateFormatted)
            ? dateUtils.formatDate(this.endDateFormatted, dateUtils.STATIC_FORMAT_DATE, dateUtils.STATIC_FORMAT_DATE_SQL) : null,
          voucherName: this.search.voucherName,
          usedSite: this.search.usedSite,
          voucherStatus: this.search.voucherStatus
        }
      }
      this.isLoading = true
      this.GET_VOUCHERS_USED_LIST(filter)
        .then(
          function (res) {
            this.isLoading = false
            let data = res.data
            let dataList = this.getDataExcelJson(
              this.exportExcelData.headerList,
              data
            )
            this.createExcelFile(dataList)
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    getDataExcelJson: function (headerList, dataList) {
      let resultList = []

      //
      // let headerListLength = headerList.length
      let nonumberHeader = headerList[0]
      let usedSiteHeader = headerList[1]
      let voucherNameHeader = headerList[2]
      let priceHeader = headerList[3]
      let dateOfUseHeader = headerList[4]
      let activationStaffHeader = headerList[5]
      let orderCodeHeader = headerList[6]
      let seriCodeHeader = headerList[7]
      let customerNameHeader = headerList[8]
      let customerPhoneHeader = headerList[9]
      let customerEmailHeader = headerList[10]
      let ownerNameHeader = headerList[11]
      let ownerPhoneHeader = headerList[12]
      let ownerEmailHeader = headerList[13]
      let voucherStatusHeader = headerList[14]
      let arrivalDateHeader = headerList[15]
      let departureDateHeader = headerList[16]
      let noteHeader = headerList[17]
      dataList.forEach((data, index) => {
        let item = {
          [this.$t(nonumberHeader)]: index + 1,
          [this.$t(usedSiteHeader)]: data.usage_info.used_site,
          [this.$t(voucherNameHeader)]: data.voucher_info.name,
          [this.$t(priceHeader)]: data.voucher_info.price,
          [this.$t(dateOfUseHeader)]: dateUtils.formatBeautyDate(
            data.usage_info.used_date
          ),
          [this.$t(activationStaffHeader)]: functionUtils.concatFullname(
            data.user_created_info.last_name,
            data.user_created_info.first_name
          ),
          [this.$t(orderCodeHeader)]: data.usage_info.code,
          [this.$t(seriCodeHeader)]: data.voucher_info.serial,
          [this.$t(customerNameHeader)]: data.customer_info.customer_name,
          [this.$t(customerPhoneHeader)]: data.customer_info.customer_phone,
          [this.$t(customerEmailHeader)]: data.customer_info.customer_email,
          [this.$t(ownerNameHeader)]: functionUtils.concatFullname(
            data.owner_info.last_name,
            data.owner_info.first_name
          ),
          [this.$t(ownerPhoneHeader)]: data.owner_info.phone,
          [this.$t(ownerEmailHeader)]: data.owner_info.email,
          [this.$t(voucherStatusHeader)]: this.$t(this.getVoucherStatusName(data.voucher_info.status)),
          [this.$t(arrivalDateHeader)]: dateUtils.formatBeautyDate(data.usage_info.start_date),
          [this.$t(departureDateHeader)]: dateUtils.formatBeautyDate(data.usage_info.end_date),
          [this.$t(noteHeader)]: data.usage_info.note
        }
        resultList.push(item)
      })
      return resultList
    },
    createExcelFile: function (jsonData) {
      let dataList = []

      dataList = jsonData
      // export json to Worksheet of Excel
      // only array possible
      var assetFile = XLSX.utils.json_to_sheet(dataList)
      // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, assetFile, 'ReportVoucherUsedSheet') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

      // export Excel file
      XLSX.writeFile(wb, 'ReportVoucherUsed.xlsx') // name of the file is 'book.xlsx'
    },
    getVoucherStatusName: function (value) {
      return functionUtils.getVoucherStatusText(value)
    },
    ...mapActions([
      'GET_VOUCHERS_USED_LIST'
    ])
  }
}
</script>

<style></style>
